
import * as _ from 'lodash'
import { defineComponent, ref, PropType, computed, Ref } from 'vue'
import { WorkingFormatKpiSetting } from '@/types/GCB2'
import TextField from '@/components/inputs/TextField.vue'
import { DEFAULT_KPI_SETTING, DEFAULT_YC_KPI_SETTING, KPI_DESCRIPTION_MAX_LENGTH, KPI_NAME_MAX_LENGTH } from '@/vars/GCB2'
import Activations from './Activations.vue'
import Conversions from './Conversions.vue'
import kpiService from '../../../../../../helpers/gcb2/kpi'
export default defineComponent({
    name: 'KpiStaffActivationStep',
    components: { TextField, Conversions, Activations },
    props: {
        value: { type: Object as PropType<WorkingFormatKpiSetting>, required: true },
        activationsList: { type: Array as PropType<{ id: string; name: string }[]> },
        yClientsConnector: {type: Object, require: false}
    },
    setup(props, { emit }) {
        const tabs = [
            { name: 'КОММУНИКАЦИИ', num: 0, id: 'communication' },
            { name: 'ОТКЛАДЫВАНИЯ', num: 1, id: 'postponed' },
            { name: 'НЕ НАПОМИНАЕМ', num: 2, id: 'banned' },
        ]
        const tabsForYc = [
            { name: 'КОММУНИКАЦИИ', num: 0, id: 'communication' },
            { name: 'ОТКЛАДЫВАНИЯ', num: 1, id: 'postponed' },
            { name: 'НЕ НАПОМИНАЕМ', num: 2, id: 'banned' },
            { name: 'СТАВКА ОПЗ', num: 3, id: 'opz' },
        ]
        const activeTabNum = ref(tabs.find(el => el.id === 'communication')?.num)
        const selectedActionId = computed(() => {
            if (props.yClientsConnector) {
                return tabsForYc.find(el => el.num === activeTabNum.value)?.id
            } else {
                return tabs.find(el => el.num === activeTabNum.value)?.id
            }            
        })
        const selectedConversion = ref()
        const formInput = (e: any) => emit('validation-updated', e)
        const clear = () => {
            const clone = Object.assign({}, props.value)
            _.set(
                clone,
                ['scheme', 'staff_action_value'],
                kpiService.mapSchemeToWorkingFormat(props.yClientsConnector ? DEFAULT_YC_KPI_SETTING.scheme : DEFAULT_KPI_SETTING.scheme).staff_action_value
            )
            emit('input', clone)
        }

        return { formInput, clear, tabs, tabsForYc, activeTabNum, selectedActionId, selectedConversion }
    },
})
