import { render, staticRenderFns } from "./StaffActionsStep.vue?vue&type=template&id=3f9315e6&scoped=true"
import script from "./StaffActionsStep.vue?vue&type=script&lang=ts"
export * from "./StaffActionsStep.vue?vue&type=script&lang=ts"
import style0 from "./StaffActionsStep.vue?vue&type=style&index=0&id=3f9315e6&prod&lang=sass&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3f9315e6",
  null
  
)

export default component.exports