
import {
    defineComponent,
    ref,
    computed,
    watch,
    onMounted,
    Ref,
    toRefs,
    ComputedRef,
    shallowRef,
    getCurrentInstance,
} from 'vue'
import Breadcrumbs from '@/components/Breadcrumbs.vue'
import { Project } from '../../types/main'
import { SourceFormatKpiSetting, WorkingFormatKpiSetting } from '../../types/GCB2'
import SpinnerLoader from '../../components/SpinnerLoader.vue'
import { CHANGE_KPI_SETTING_TYPES, DEFAULT_KPI_SETTING, DEFAULT_YC_KPI_SETTING } from '../../vars/GCB2'
import TextField from '../../components/inputs/TextField.vue'
import * as _ from 'lodash'
import {
    getKpiSettings,
    kpiCreateSetting,
    kpiDeleteSetting,
    kpiEditSetting,
    kpiSettingsGetActivations,
} from '../../api/chisai/GCB2'

import useRouter from '../../hooks/useRouter'

import RecordValueStep from '../../components/chisai/GCB2/kpi/steps/RecordValue/RecordValueStep.vue'
import VisitValueStep from '../../components/chisai/GCB2/kpi/steps/VisitValue/VisitValueStep.vue'
import StaffActionsStep from '../../components/chisai/GCB2/kpi/steps/StaffActions/StaffActionsStep.vue'
import NameAndDecriptionStep from '../../components/chisai/GCB2/kpi/steps/NameAndDecriptionStep.vue'
import { replaceElementAtIndex } from '../../utils'
import kpiService from '../../helpers/gcb2/kpi'
import { useKpiStore } from '../../store/stores/gcb2/kpi'
import { TrackCodeExecutionError, useTrackCodeExecution } from '@root/src/hooks/useTrackCodeExecution'
import TrackCodeExecutionErrorRepresentation from '@root/src/components/TrackCodeExecutionErrorRepresentation.vue'
import { CONNECTOR_TYPES } from '@root/src/vars/general'

export default defineComponent({
    components: {
        Breadcrumbs,
        TextField,
        SpinnerLoader,
        NameAndDecriptionStep,
        TrackCodeExecutionErrorRepresentation,
    },
    setup(props, {}) {
        const root = getCurrentInstance()!.proxy
        const { route } = useRouter()
        const kpiStore = useKpiStore()

        const actionLoading = ref<boolean>(false)
        const activationsList = shallowRef<{ id: string; name: string }[]>([])
        const mode = ref<null | string>(null)
        const kpiSettingId = ref<null | number>(null)
        const kpiSetting = ref<WorkingFormatKpiSetting>({} as WorkingFormatKpiSetting)
        const stepsIndexes = ref<number[] | number>([])
        const activeStepsIndexes = ref<number[] | number>([])
        const project = computed(
            (): Project => {
                return root.$store.getters.projectById(root.$router.currentRoute.params.id)
            }
        )
        const yClientsConnector = computed(() => {
            return project.value.connectors.find(el => el.connectorType === CONNECTOR_TYPES.yClients)
        })
        const title = computed((): string =>
            mode.value === CHANGE_KPI_SETTING_TYPES.create
                ? 'Новая схема расчета KPI'
                : 'Редактирование схемы расчета KPI'
        )

        const breadcrumbs: ComputedRef<any[]> = computed(() => [
            {
                text: 'ВАШИ ПРОЕКТЫ',
                to: '/projects',
            },
            {
                text: project.value.name,
                to: `/project/${project.value.id}`,
            },
            {
                text: 'Kpi сотрудников',
                to: `/project/${project.value.id}/GCB2/kpi?section=settings`,
            },
            {
                text: title.value,
                to: '',
                disabled: true,
            },
        ])
        const steps = ref([
            {
                id: 1,
                num: 1,
                title: 'Название и описание',
                contentComponent: NameAndDecriptionStep,
                ref: 'setting-1',
                saved: false,
                saveBtnDisabled: false,
            },
            {
                id: 2,
                num: 2,
                title: 'Действия сотрудников',
                contentComponent: StaffActionsStep,
                ref: 'setting-2',
                saved: false,
                saveBtnDisabled: false,
                props: { activationsList },
            },
            {
                id: 3,
                num: 3,
                title: 'Факты записей',
                contentComponent: RecordValueStep,
                ref: 'setting-3',
                saved: false,
                saveBtnDisabled: false,
                props: { activationsList },
            },
            {
                id: 4,
                num: 4,
                title: 'Состоявшиеся посещения',
                contentComponent: VisitValueStep,
                ref: 'setting-4',
                saved: false,
                saveBtnDisabled: false,
                props: { activationsList },
            },
        ])
        const savedSteps = computed(() => steps.value.filter(el => el.saved))
        const availableStepsIds = computed(() => {
            if (mode.value === CHANGE_KPI_SETTING_TYPES.create) {
                const nextStepIndex =
                    steps.value.findIndex(el => el.id === savedSteps.value[savedSteps.value.length - 1]?.id) +
                    1
                const nextStep = steps.value[nextStepIndex]
                return [...savedSteps.value.map(el => el.id), nextStep?.id]
            } else {
                return steps.value.map(el => el.id)
            }
        })
        const saveBtnDisabled = computed(() =>
            mode.value === CHANGE_KPI_SETTING_TYPES.edit
                ? !kpiSetting.value.name
                : savedSteps.value.length < steps.value.length - 1
        )
        const expandDisabled = computed(() => mode.value === CHANGE_KPI_SETTING_TYPES.edit)
        const panelsIsMultiplie = computed(() => mode.value === CHANGE_KPI_SETTING_TYPES.edit)

        const goToSettings = () => {
            root.$router.push(`/project/${project.value.id}/GCB2/kpi?section=settings`)
        }
        const deleteSetiing = async () => {
            actionLoading.value = true
            const { data, error } = await kpiDeleteSetting({
                id: kpiSettingId.value as number,
                projectId: project.value.id,
            })
            if (error) {
                root.$store.dispatch('callNotify', 'Ошибка')
                actionLoading.value = false
                return
            }
            actionLoading.value = false
            goToSettings()
        }
        const saveSettings = async () => {
            actionLoading.value = true
            const request = mode.value === CHANGE_KPI_SETTING_TYPES.edit ? kpiEditSetting : kpiCreateSetting
            const { data, error } = await request({
                id: kpiSettingId.value as number,
                projectId: project.value.id,
                settings: {
                    name: kpiSetting.value.name as any,
                    description: kpiSetting.value.description as any,
                    is_default: kpiSetting.value.is_default as boolean,
                    scheme: kpiService.mapSchemeToSourceFormat(kpiService.addRanges(kpiSetting.value.scheme)),
                },
            })
            if (error) {
                root.$store.dispatch('callNotify', 'Ошибка')
                actionLoading.value = false
                return
            }
            actionLoading.value = false
            goToSettings()
        }
        const openCreateCategoryModal = () => {
            kpiStore.openCategoryModal({ state: 'create' })
        }
        const activeStepsIndexesIncludes = (index: number) => {
            return Array.isArray(activeStepsIndexes.value)
                ? activeStepsIndexes.value.includes(index)
                : [activeStepsIndexes.value].includes(index)
        }

        const updateSaveButton = (e: boolean, step: any, index: number) =>
            (steps.value = replaceElementAtIndex(
                steps.value,
                index,
                Object.assign({}, step, { saveBtnDisabled: !e })
            ))
        const saveStep = (step: any, index: number) => {
            steps.value = replaceElementAtIndex(steps.value, index, Object.assign({}, step, { saved: true }))
            const nextStepIndex = index + 1
            stepsIndexes.value = nextStepIndex
            activeStepsIndexes.value = [nextStepIndex]
        }
        const clearStep = (step: any) => {
            switch (step.id) {
                case 1:
                    kpiSetting.value.name = ''
                    kpiSetting.value.description = ''
                    break
                case 2:

                default:
                    break
            }
            ;(root.$refs[step.ref] as any)[0].clear()
        }
        watch(
            () => route,
            () => {
                mode.value = route.value.query.mode as string
                kpiSettingId.value = Number(route.value.query.id) || null
            },
            { immediate: true }
        )
        watch(stepsIndexes, () => {
            if (mode.value === CHANGE_KPI_SETTING_TYPES.create) {
                activeStepsIndexes.value = Array.isArray(stepsIndexes.value)
                    ? [...stepsIndexes.value]
                    : [stepsIndexes.value].filter(el => !_.isNil(el))
            }
        })

        const onCreated = async () => {
            activationsList.value = await kpiSettingsGetActivations({ projectId: project.value.id }).then(
                res => res.data ?? []
            )
            await kpiStore.fetchCategories(project.value.id)
            if (mode.value === CHANGE_KPI_SETTING_TYPES.edit) {
                stepsIndexes.value = steps.value.map((el, i) => i)
                const SourceFormatKpiSetting = await getKpiSettings({
                    projectId: project.value.id,
                    full: true,
                    id: kpiSettingId.value as number,
                }).then(res => res.data![0])
                kpiSetting.value = Object.assign({}, DEFAULT_KPI_SETTING, SourceFormatKpiSetting, {
                    scheme: kpiService.mapSchemeToWorkingFormat(
                        yClientsConnector.value ?
                        _.merge({}, DEFAULT_YC_KPI_SETTING.scheme, SourceFormatKpiSetting.scheme) :
                        _.merge({}, DEFAULT_KPI_SETTING.scheme, SourceFormatKpiSetting.scheme)
                    ),
                })
            }
            if (mode.value === CHANGE_KPI_SETTING_TYPES.create) {
                kpiSetting.value = Object.assign({}, DEFAULT_KPI_SETTING, {
                    scheme: kpiService.mapSchemeToWorkingFormat(yClientsConnector.value ? DEFAULT_YC_KPI_SETTING.scheme : DEFAULT_KPI_SETTING.scheme),
                }) as any
            }
        }
        const onError = () => {
            root.$store.dispatch('callNotify', `При обработке схемы КПИ возникла ошибка!`)
        }

        const onCreatedTCE = useTrackCodeExecution(onCreated, { onError, isLoadingDefault: true })

        onCreatedTCE.run()

        onMounted(() => {})
        return {
            CHANGE_KPI_SETTING_TYPES,
            actionLoading,
            mode,
            stepsIndexes,
            activeStepsIndexes,
            availableStepsIds,
            kpiSettingId,
            kpiSetting,
            project,
            breadcrumbs,
            title,
            expandDisabled,
            panelsIsMultiplie,
            steps,
            kpiService,
            saveBtnDisabled,
            clearStep,
            saveStep,
            goToSettings,
            activeStepsIndexesIncludes,
            deleteSetiing,
            saveSettings,
            updateSaveButton,
            openCreateCategoryModal,
            onCreatedTCE,
            yClientsConnector
        }
    },
})
